import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay } from "swiper/modules";

import {
  SliderBlock,
  SliderImg, SliderWrapper,
  UpBlock,
  UpBlockButton,
  UpTextBlock
} from "./styled";
import "swiper/css";
import "swiper/css/navigation";
import slide from "./../../images/slider/first.png";
import slide_1 from "./../../images/slider/second.png";
import slide_2 from "./../../images/slider/third.png";
import {Link} from "react-router-dom";
import {routes} from "./../../shared/routes/routes";

export const Slider = () => {
  return (
    <SliderWrapper>
    <Swiper
      navigation={true}
      modules={[Navigation, Autoplay]}
      autoplay={{
        delay: 25000,
        disableOnInteraction: false
      }}>
      <SwiperSlide>
        <SliderBlock>
          <SliderImg
            style={{ background: `url(${slide}) center center /cover` }}>
            <UpBlock>
              <UpTextBlock>
                НАЧИНАЕТСЯ РЕГИСТРАЦИЯ <br/>
                НА 2025-2026 УЧЕБНЫЙ ГОД <br/>
                (514) 772-5012 <br/>
                (514) 795-4677 <br/>
                (514) 712-5012
              </UpTextBlock>
              <Link to={routes.appointment}>
              <UpBlockButton>
                Подробнее
              </UpBlockButton>
              </Link>
            </UpBlock>
          </SliderImg>
        </SliderBlock>
      </SwiperSlide>
      <SwiperSlide>
        <SliderBlock>
          <SliderImg
            style={{ background: `url(${slide_1}) center center /cover` }}>
            <UpBlock>
              <UpTextBlock>
                ПОДГОТОВКА К ПОСТУПЛЕНИЮ <br />
                В ПРЕСТИЖНЫЕ ЧАСТНЫЕ ШКОЛЫ <br />
                - Бесплатный курс математики <br />
                - Углублённый курс точных наук <br />
                - Проведение пробных экзаменов совместно с SUCCÈS SCOLAIRE
              </UpTextBlock>
            </UpBlock>
          </SliderImg>
        </SliderBlock>
      </SwiperSlide>
      <SwiperSlide>
        <SliderBlock>
          <SliderImg
            style={{ background: `url(${slide_2}) center center /cover` }}>
            <UpBlock>
              <UpTextBlock>
                Скидки <br/>
                в частные школы <br/>
                - Villa Maria - 10% <br/>
                - Collége Français - 20% <br/>
                - L'École des Premières Lettres - 30%
              </UpTextBlock>
            </UpBlock>
          </SliderImg>
        </SliderBlock>
      </SwiperSlide>
    </Swiper>
    </SliderWrapper>
  );
};
