import axios from "axios";
import { useQuery } from "react-query";
import pavel from './pavel.jpg'

const fetchNews = async () => {
  const { data } = await axios.get(
    "https://api.mechtacenter.net/api/v1/get/news-all/list"
  );
  return data.results.map((item: any) => ({
    ...item,
    description: cleanDescription(item.description)
  }));
};

const cleanDescription = (description: any) => {

  let cleanedString = description.replace(/<\/?[^>]+(>|$)/g, '');

  cleanedString = cleanedString.replace(/&[^;\s]+;/g, '');

  let resultArray = cleanedString.split(/\s*<br\s*\/?>\s*/).map((s: any) => s.trim()).filter(Boolean);

  return resultArray.join(' ');
};

export const useNews = () => {
  const { data } = useQuery("news", fetchNews, {
    onError: (error) => {
      console.log("error", error);
    }
  });

  return {
    news: data
  };
};
